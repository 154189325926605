
/*---------------------------------------------------CSS BORDER COLORS--------------------------------------------------------------*/


/*body {*/
/*  border: 4px solid #96ec15;*/
/*}*/

/*header,*/
/*footer {*/
/*  border: 4px solid #15ecec;*/
/*}*/

/*nav {*/
/*  border: 4px solid darkorchid;*/
/*}*/

/*main {*/
/*    border: 4px solid #0b0a0a;*/
/*}*/

/*ul {*/
/*    border: 4px solid #0059ff;*/
/*}*/

/*li {*/
/*    border: 4px solid orange;*/
/*}*/

/*div {*/
/*  border: 4px solid #e76455;*/
/*}*/

/*article {*/
/*  border: 4px solid darkred;*/
/*}*/

/*p {*/
/*  border: 4px solid darkcyan;*/
/*}*/

/*h6 {*/
/*  border: 4px solid darkslateblue;*/
/*}*/

/*form {*/
/*  border: 4px solid hotpink;*/
/*}*/

/*img {*/
/*  border: 4px solid yellow;*/
/*}*/

/*.image-wrapper {*/
/*    border: 4px solid #fb9468;*/
/*}*/

/*section {*/
/*    border: 4px solid #444040;*/
/*}*/


@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Roboto:wght@300;400;700&display=swap');


/*---------------------------------------------------TABLE OF CONTENTS--------------------------------------------------------------*/
/*////////////////////////

    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Header
        4.2 Introduction
        4.3 Services
        4.4 Portfolio
        4.5 Contact
        4.6 Footer
    5. Media Queries
////////////////////////*/


/* ------------------------------- 1. Global Styles -------------------------------- */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


body {
    /*font-family: Avenir, Arial, sans-serif;*/
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 20px;
    background-color: white;
    /*color: #617d98;*/
    color: #42ACA5;
    text-align: center;
}


/* ------------------------------- 2. Typography ----------------------------------- */

h1, h2, h3, h4, h5, h6 {
    font-family: Merriweather, 'Times New Roman', serif;
}

h1, h2 {
    font-size: 46px;
    padding: 16px 0;
}

h3 {
    font-size: 36px;
}

h4 {
    font-size: 32px;
    padding: 12px 0;
    margin: 24px 0;
}

h5 {
    font-size: 32px;
    padding: 12px 0;
}

h6 {
    font-size: 32px;
    line-height: 1.5;
    padding: 12px 0;
}

p {
    margin: 12px 0;
}

/* ------------------------------- 3. Layout --------------------------------------- */


/* --------------- 3.1 Reusables ----------------------- */

.outer-content-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


    .inner-content-container {
        flex-basis: 1400px;
        flex-shrink: 1;
        flex-grow: 0;
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .image-wrapper-small {
    max-width: 200px;
    flex-shrink: 1;
    flex-grow: 0;
        margin: 20px;
    }


    .image-wrapper-big {
    max-width: 400px;
    flex-shrink: 1;
    flex-grow: 0;
        /*margin: 20px;*/
    }


    img {
    max-width: 100%
    }


    .default-text-restrictor {
        max-width: 850px;
        text-align: center;
    }

    .default-area-padding {
        padding: 20px 0;
    }


    /* --------------- 3.2 Other --------------------------- */

    a,
    a:visited,
    a:link {
        text-decoration: none;
        /*color: #c6572f;*/
        /*color: white;*/
        /*color: #617d98;*/
        color: #42ACA5;
        /*color: #504e4e;*/
    }

    button {
        font-size: 14px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: white;
        /*background-color: #7a7777;*/
        /*background-color: #617d98;*/
        background-color: #42ACA5;
        border: none;
        border-radius: 50px;
        box-shadow: 0 5px 8px 4px rgba(0, 0, 0, .2);
        padding: 25px 40px;
        margin: 28px 0;
    }

    button:hover {
        cursor: pointer;
        background: #7a7777;
        /*background: #617d98;*/
    }


    /* ------------------------------- 4. Areas ---------------------------------------- */

    /* --------------- 4.1 Header ------------------------- */

    #header {
        background: #284949;
        background: linear-gradient(129deg, #284949 0%, #315050 26%, #f8d857 100%);
        /*background: linear-gradient(129deg, #284949 0%, #315050 26%, #c6572f 100%);*/
        /*background: #111111;*/
        color: white;
        padding: 30px 0;
        position: relative;
    }

    .navbar {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
    }

    .navbar ul {
        flex-basis: 400px;
        flex-shrink: 1;
        flex-grow: 0;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .header-content-navigation a {
        /*color: #617d98;*/
        color: #42ACA5;
    }

    .header-content {
        /*padding: 10px 0;*/
        /*margin-bottom: 88px;*/
        /*margin: 44px;*/
        font-size: 28px;
    }

    .header-content p {
    /*color: #617d98;*/
    /*color: #42ACA5;*/
        color: #504e4e;
    }

    /**/
    .header__squiggle {
        display: block;
        width: 78px;
        height: 100px;
        margin: 60px 0 0 0;
        position: absolute;
        bottom: 0;
        left: calc(50% - 39px);
        /*background-image: url(./assets/squiggle_line_stripe.svg);*/
    }
    /**/


    /* --------------- 4.2 Introduction ------------------- */

    #intro {

        /*background-color: #bab7b7;*/
        background-color: white;
        color: #3e6565;
    }

    .intro-title {
        color: #3e6565;
    }

    .intro__link {
        color: #0c0c0c;
        font-weight: 500;
        /*margin-top: 100px;*/
    }



/* --------------- 4.3 Services------------------------ */

#services {
    background-color: white;
}

.services-title {
    margin: 20px;
    /*color: #617d98;*/
    color: #42ACA5;
}


/*#services > div {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: center;*/
/*    flex-wrap: wrap;*/
/*    gap: 40px;*/
/*    padding: 100px 0 50px 0;*/
/*}*/


.grid-service-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}


.grid-service-tile {
    flex-basis: 480px;
    flex-grow: 0;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    padding: 40px;
    margin: 10px;
    background-color: #284949;
    /*border: 2px solid #c6572f;*/
    border-radius: 40px;
    box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.1);
    /*color: #617d98;*/
    color: #42ACA5;
    font-size: 16px;
}

.service-tile__title {
    color: white;
    font-size: 24px;
}

.service-tile__icon {
    width: 80px;
}


    /* --------------- 4.4 Portfolio --------------------------- */

    /*.work-article:nth-of-type(odd) {*/
    /*    flex-direction: row-reverse;*/
    /*}*/
    /*.work-article__image-wrapper img {*/
    /*    width: 100%;*/
    /*    box-shadow: 0 32px 28px -10px rgba(0, 0, 0, 0.22);*/
    /*}*/


:root {
    --transition: all 0.3s linear;
    --radius: 0.25rem;
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}


#portfolio {
    background-color: white;
}


.portfolio-title {
    /*color: #617d98;*/
    color: #42ACA5;
}


.portfolio-container {
    margin: 40px;
    /*margin: 0 auto;*/
    /*margin-top: 4rem;*/
    width: 80vw;
    height: 460px;
    max-width: 800px;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
}


.portfolio-img {
    /*border-radius: 50%;*/
    margin-bottom: 1rem;
    width: 200px;
    height: 200px;
    /*object-fit: cover;*/
    /*border: 4px solid;*/
    /*color: grey;*/
    /*box-shadow: var(--dark-shadow);*/
}

.portfolio-domain-name {

}


.portfolio-text {
    max-width: 35em;
    margin: 0 auto;
    margin-top: 2rem;
    line-height: 2;
    /*color: #617d98;*/
    color: #42ACA5;
}


/*.portfolio-icon {*/
/*    font-size: 3rem;*/
/*    margin-top: 1rem;*/
/*    color: grey;*/
/*}*/


.prev,
.next {
    position: absolute;
    top: 200px;
    transform: translateY(-80%);
    background: #284949;
    color: white;
    width: 1.25rem;
    height: 1.25rem;
    display: grid;
    place-items: center;
    border-color: transparent;
    font-size: 1rem;
    border-radius: var(--radius);
    cursor: pointer;
    transition: var(--transition);
}

.prev:hover,
.next:hover {
    /*background: grey;*/
    /*background: #617d98;*/
    background: #42ACA5;
}

.prev {
    left: 0;
}

.next {
    right: 0;
}


@media (min-width: 800px) {
    .text {
        max-width: 45em;
    }

    .prev,
    .next {
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
    }
}


article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
}


article h4 {
    text-transform: uppercase;
    color: black;
    margin-bottom: 0.25rem;
}


article h6 {
    text-transform: uppercase;
    color: black;
    margin-bottom: 0.25rem;
}


article.activeSlide {
    opacity: 1;
    transform: translateX(0);
}

article.lastSlide {
    transform: translateX(-100%);
}

article.nextSlide {
    transform: translateX(100%);
}


    /* --------------- 4.5 Contact ------------------------ */

    #contact {
        background: #284949;
        background: linear-gradient(129deg, #f8d857 0%, #315050 26%, #284949 100%);
        /*background: linear-gradient(129deg, #c6572f 0%, #315050 26%, #284949 100%);*/
        color: white;
        position: relative;
    }

    #contact p{
        /*color: #617d98;*/
        color: #42ACA5;
    }

    .contact-content__form {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
    }


    .contact-content__form input,
    .contact-content__form textarea {
        background-color: white;
        border: 1px solid grey;
        border-radius: 4px;
        font-family: 'Roboto', sans-serif;
        padding: 20px;
        display: block;
        font-size: 16px;
        color: #7A7A7A;
        width: 100%;
    }

    .contact-content__form input::placeholder,
    .contact-content__form textarea::placeholder {
        color: grey;
    }

    .contact-content__form label {
        font-size: 16px;
        font-weight: 600;
        padding: 20px 0;
    }

    .contact-content__form button {
        width: 200px;
        align-self: center;
    }


    /* --------------- 4.6 Footer ------------------------- */

    #footer {
        background-color: #284949;
        color: grey;
        text-align: center;
        padding: 20px 0;
        font-size: 14px;
    }


    /* ------------------------------- 5. Media Queries -------------------------------- */

    @media screen and (max-width: 576px) {
        h1, h2 {
            font-size: 36px;
            padding: 16px 0;
        }

        h3 {
            font-size: 26px;
        }


        h6 {
            font-size: 22px;
            line-height: 1.5;
            padding: 12px 0;
        }

        .header-content__navigation {
            justify-content: center;
        }

        .header-content__navigation a {
            font-size: 16px;
            font-weight: 600;
        }

        .intro__link {
            margin: 50px 0 0 0;
        }

    }

